import React from 'react';
import * as styles from './UpcomingDates.module.scss'
import * as style from '../../../styles/general.module.scss'
import { Link } from 'gatsby';



export default function UpcomingDates ({ data }) {
   
    var ctaStyle= {}
    if(data.optionalLabel !==null){
        ctaStyle = {display:"block"}
    }
    else{
        ctaStyle = {display:"none"}
    }
    const cardsList = [];
   
        data.dateCards.forEach(card => {
          cardsList.push(<Link to={card.url} className={styles.dateCard}>

                <h4>{card.postType}</h4>
                <h2>{card.dates}</h2>
                <h3>{card.title}</h3>

            </Link>)
        })
  

    return (
        <div className={styles.upcomingDates} >
            <div className={style.layout}>
                <h1>{data.title}</h1>
                <div className={styles.upcomingDatesGrid} >
                    {cardsList}
                </div>
                <Link to={data.ctaLink}><span style={ctaStyle}>{data.optionalLabel}</span></Link>
            </div>
        </div>
    )
}

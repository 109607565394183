import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import EventsRetreatsBuilder from '../components/EventsRetreatsBuilder'

export const query = graphql`
  query EventAndRetreatsTemplateQuery($id: String!) {
    page: sanityEventAndRetreat(id: {eq: $id}) {
      id
      slug {
        current
      }
      ...eventsRetreatsBuilder
    }
  }
`

const PageTemplate = props => {
  const {data} = props
  const {page} = data
  const {eventsRetreatsBuilder} = page
  return (
    <Layout>
      <EventsRetreatsBuilder sections={eventsRetreatsBuilder} />
    </Layout>
  )
}

export default PageTemplate
